.siderContainer {
  width: 70px;
  background-color: white;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  .sectionListContainer {
    display: flex;
    flex-direction: column;
    padding: 13px;
    height: 100%;
    .sectionList {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: inherit;
      scrollbar-width: none;
      &:not(:first-child) {
        div {
          margin-top: 40px;
          .monitoringIcon {
            width: 44px;
            height: 44px;
          }
        }
      }
      img {
        cursor: pointer;
      }
    }
  }
  .siderLogo {
    cursor: pointer;
  }
}

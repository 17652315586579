.scrollarea .scrollbar-container.vertical .scrollbar {
  width: 0px !important;
}

.scrollarea .scrollbar-container:hover {
  background: unset;
}

.formField {
  .inputLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    position: absolute;
    font-size: 10px;
    top: 5px;
    left: 17px;
    color: #b9b9b9;
  }
}

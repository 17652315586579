.doctorChat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 580px;
  background: #ffffff;
  border-radius: 8px;
  height: calc(100% - 65px);
  margin-right: 20px;
  &.video {
    height: calc(100% - 65px);
  }
}
.DoctorChatTitle,
.chatDate,
.messageText {
  // font-family: SF UI Display, sans-serif;
  font-family: "SF UI Display Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  a {
    color: white;
  }
  video,
  img {
    max-width: 100%;
    border-radius: 8px;
  }

  span {
    word-wrap: break-word;
  }
}
.videoCall {
  // background-color: lightgreen;

  position: relative;
  max-width: 580px;
  height: 320px;
  .opponentVideo {
    position: relative;
    width: 100%;
    max-width: 580px;
    height: 320px;
    z-index: 10;
  }
  .myVideo {
    position: absolute;
    width: 85px;
    top: 20px;
    left: 20px;
    z-index: 15;
    border-radius: 12px;
  }

  .icon-buttons {
    position: absolute;
    z-index: 40;
    bottom: 0;
    // background-color: lightpink;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .icon-button {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 6px;
      // margin: 0 8px;
      cursor: pointer;
      transition: all 300ms;
      &:hover {
        background-color: #f6f7fb;
      }
    }
  }

  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    z-index: 144;
    background-color: #f6f7fb;
    padding-right: calc(320px + 4vw);

    display: flex;
    justify-content: center;
    // background-color: aquamarine;

    .myVideo {
      position: absolute;
      width: 140px;
      top: 40px;
      left: 40px;
      z-index: 146;
      border-radius: 8px;
    }
    .opponentVideo {
      position: relative;
      width: 90%;
      max-width: 90%;
      height: 100vh;
      z-index: 145;
    }

    .icon-buttons {
      justify-content: center;
      bottom: 24px;
      z-index: 1000;
      // padding-right: calc(320px + 4vw);

      .icon-button {
        width: 50px;
        height: 50px;
        padding: 12px;
        margin: 0 15px;
      }
    }
  }
}
.DoctorChatTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #272d2d;
  padding: 0 20px;
  border-bottom: 1px solid #e7e7e7;
  height: 72px;
  min-height: 72px;
  width: 100%;
  .chatTitle {
    font-family: "SF UI Display Medium";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .connectToolBar {
    display: flex;
    align-items: center;
    > img {
      margin-right: 22px;
      width: 22px;
      cursor: pointer;
      &:last-child {
        margin: unset;
      }
    }
  }
}
.chatDate {
  // width: 66px;
  height: 26px;
  background: #f6f7fb;
  color: #272d2d;
  border-radius: 15px;
  margin: 20px auto;
  font-size: 12px;
  line-height: 14px;
  padding: 6px 10px;
}
.messagesArea {
  // min-height: 78%;
  // background-color: lightcoral;
  scrollbar-width: none;
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  &.video {
    height: 25%;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: transparent;
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #4b9bf5;
  }
}
.messageText {
  align-self: flex-end;
  //width: 45%;
  left: 806px;
  top: 320px;
  background: #4b9bf5;
  border-radius: 8px;
  font-size: 13px;
  line-height: 16px;
  margin: 20px;
  color: #ffffff;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 18px;

  &.with-media {
    //width: 75%;
  }
}
.messageText.occupantMessage {
  background: #f6f7fb;
  color: #272d2d;
  align-self: flex-start;
  a {
    color: #4b9bf5;
  }
}
.msgTime {
  display: flex;
  margin: 20px 0;
  font-family: SF UI Display, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  // color: #FFFFFF;
}
.checkSign {
  margin-left: 10px;
}
.messageText.occupantMessage .checkSign {
  display: none;
}
.chatFooter {
  height: 90px;
  min-height: 90px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  .startConsult {
    width: calc(100% - 30px);
    height: 50px;
    background: #4b9bf5;
    border-radius: 8px;
    border: none;
    outline: none;
    color: white;
    font-family: SF UI Display, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    // margin-top: 20px;
    &.video {
      margin-top: 0;
    }
  }
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 90px;
  padding-bottom: 16px;

  form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;

    .icon-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon-button {
        border-radius: 8px;
        padding: 8px;
        margin: 0 8px;
        cursor: pointer;
        transition: all 300ms;
        &:hover {
          background-color: #f6f7fb;
        }
      }
    }
    .textarea_wrapp {
      width: 75%;
      background: #f6f7fb;
      border-radius: 8px;
      padding: 20px;
    }
    .formFieldDoc.textAreaDoc {
      margin: 0;
      width: 100%;
      max-width: 100%;
      border: none;
      background: #f6f7fb;
      resize: none;
      height: auto;
      overflow: auto;
      line-height: 16px;
      padding: 0;
    }
  }
}

.templates-modal-content {
  //position: relative;
  z-index: 111111111222;
  width: 50vw;
  //min-height: 50vh;
  border-radius: 8px;

  &.help-modal {
    width: 480px;
    height: 545px;
    background: #ffffff;
    border-radius: 8px;
  }

  &.image-modal {
    border-radius: 8px;
    overflow: scroll;
    //min-width: 60vw;
    max-width: 80vw;
    //min-height: 50vh;
    max-height: 95vh;
    display: flex;
    justify-content: center;
    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .templates-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #272d2d;
    padding: 24px 20px;
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    &.modal-header {
      justify-content: flex-end;
    }

    &.image-header {
      padding: 0px;
      .icon-button {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0%;
        right: -10%;
        border-radius: 50%;
        background: #e5e5e5;
      }
    }

    .icon-button {
      border-radius: 8px;
      padding: 8px;
      margin: 0 8px;
      cursor: pointer;
      transition: all 300ms;
      &:hover {
        background-color: #f6f7fb;
      }
    }

    span {
      // font-family: SF UI Display;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
    }
    /* identical to box height */
  }

  .templates-content {
    display: flex;
    //min-height: 42vh;
    background: white;
    border-radius: 0 0 8px 8px;

    .templates-modal-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 20px;
      border-top: 2px solid transparent;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      color: #272d2d;
      transition: all 300ms;
      cursor: pointer;

      &.selected,
      &:hover {
        background-color: #f6f7fb;
      }
    }

    .groups {
      border-right: 2px solid #e7e7e7;
      .templates-modal-item {
        border-top: 2px solid #e7e7e7;
      }

      padding-bottom: 4em;
      width: 33%;
    }
    .messages-list {
      width: 67%;
      border-top: 2px solid #e7e7e7;
      padding-bottom: 4em;

      .templates-modal-item {
        &:first-child {
          border-top: none;
        }
      }
    }
  }

  .help-modal-content {
    width: 75%;
    margin: 15px auto;
    font-family: SF UI Display, sans-serif;
    flex-direction: column;
    text-align: center;
    font-style: normal;
    letter-spacing: 0;

    .help-modal-header {
      font-size: 28px;
      font-weight: 700;
      line-height: 33px;
    }

    .help-modal-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;

      .help-modal-text-phone {
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
      }
    }

    .help-modal-footer {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 50px;

      .help-modal-buttons {
        font-family: SF UI Display, sans-serif;
        height: 50px;
        min-width: 150px;
        margin: 25px 0;
        border-radius: 8px;
        color: white;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        border-color: transparent;

        &.red-button {
          background: #fd3845;
        }
        &.blue-button {
          background: #4b9bf5;
        }
      }
    }
  }

  .image-modal-content {
    background: none;
    img {
      // width: 100%;
      max-width: 90vw;
      height: 100%;
      max-height: 95vh;
    }
  }
}

.full {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    zoom: 0.7;
  }
}

.document-attach {
  position: relative;

  .file-inputs {
    position: absolute;

    height: 80px;
    width: 200px;

    top: -80px;
    right: 0;

    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

    .label {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 20px;
      height: 40px;
      width: 100%;
      cursor: pointer;
      transition: all 300ms;
      border-radius: 8px;
      font-size: 13px;
      &:hover {
        background-color: #f6f7fb;
      }
    }

    .fileinput {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
}

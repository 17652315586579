.editLifeAnamnezForm {
  .formField {
    .fieldInput {
      //   .inputLabel {
      //     font-weight: 500;
      //     font-size: 14px;
      //     line-height: 17px;
      //   }
      input {
        // background: unset;
        &:disabled {
          color: #bec0c0;
        }
      }
    }
  }
}

.btn {
  width: 100%;
  max-width: 100%;
  height: 50px;
  //max-height: 300px;
  border-radius: 8px;
  border: 0;
  outline: unset;
  background: #4b9bf5;
  color: #ffffff;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  cursor: pointer;

  & span > .selectedLabels {
    display: flex;
    flex-direction: column;
    padding: 5px;
    span {
      margin: 5px 0;
    }
  }

  &.freeTopSpace {
    margin-top: 30px;
  }

  &:disabled {
    background: #f6f7fb;
    color: #b9b9b9;
  }

  &.secondary {
    @extend .btn;
    display: flex;
    padding: 14px;
    background: #f6f7fb;
    color: #272d2d;
    justify-content: space-between;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      height: auto;
      img {
        margin-right: 10px;
        max-width: 34px;
      }
    }
    &:disabled {
      background: #f6f7fb;
      color: #b9b9b9;
    }
  }
  &.danger {
    @extend .btn;
    background: #fd3845;
    width: 100%;
    max-width: 140px;
    height: 40px;
    margin: 0 auto;
    &:disabled {
      background: #f6f7fb;
      color: #b9b9b9;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 10px;
      }
    }
  }
}

.wrappNewEmployeeForm {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 350px;
  background: #ffffff;
  border-radius: 8px;
  padding: 15px;
  overflow: hidden;
  .formTitle {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    padding: 0 5px;
    margin-bottom: 30px;
    &.edit {
      @extend .formTitle;
      justify-content: space-between;
    }
    img {
      cursor: pointer;
    }
  }
  .toggleField {
    margin-bottom: 20px;
  }
  .newEmployeeForm {
    width: 100%;
    max-width: 350px;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    .formContent {
      width: 100%;
      margin-top: 30px;
      height: 100%;
      overflow: scroll;
      min-width: 321px;

      &.relative {
        position: relative;
      }
      .wrapper_checkbox {
        margin-bottom: 20px;
      }
      .formItemError {
        font-size: 10px;
        padding-top: 5px;
        padding-left: 10px;
      }
      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 2px;
        background: unset;
      }
      &::-webkit-scrollbar-thumb {
        width: 4px;
        height: 10%;
        border-radius: 2px;
        background: #4b9bf5;
      }
      .addingImg.show {
        .photoUploadFieldContainer {
          height: calc(100% - 55px);
          .uploadFooter {
            button[type="button"] {
              height: 50px;
            }
          }
        }
      }
      .inputFile {
        max-width: 100%;
      }
      .specAvatar {
        display: flex;
        justify-content: space-between;
        padding: 8px 16px 8px 15px;
        background: #f6f7fb;
        border-radius: 8px;
        margin-bottom: 20px;
        .cropPhoto {
          width: 34px;
          height: 34px;
          margin-right: 15px;
          border-radius: 8px;
        }
        .fileName {
          margin: 0;
          font-size: 12px;
          width: 100%;
          max-width: 210px;
          overflow: hidden;
        }
        .trashIcon {
          display: flex;
          align-items: center;
          cursor: pointer;
          width: 18px;
        }
      }
      .dateOfBith {
        position: relative;
        width: 100%;
        max-width: 320px;
        display: flex;
        align-items: center;
        height: 77px;
        .formField {
          width: 100%;
        }
        img {
          width: 18px;
          height: 18px;
        }
        .closeImg,
        .calendarImg {
          position: absolute;
          top: 16px;
        }
        .closeImg {
          right: 40px;
        }
        .calendarImg {
          right: 10px;
        }
      }
      .datePicker {
        width: 100%;
        max-width: 100%;
        // padding: 16px;
        background: #f6f7fb;
        border-radius: 8px;
        .rdtPicker {
          width: 310px;
          right: 4px;
          .rdtDays {
            max-width: 350px;
          }
          tr {
            margin: 0;
            padding: 0;
            border: 0;
            display: flex;
            td.rdtDay {
              flex: 0 0 30px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              &:hover {
                background: #ececec;
                border-radius: 50%;
              }
              @media screen and (max-width: 480px) {
                flex: 0 0 34px;
                width: 34px;
                height: 34px;
              }
            }
          }
        }
      }
      label {
        &.label {
          &.inputFile {
            img {
              width: 22px;
            }
          }
        }
      }
    }
    .employeePreviewCard {
      width: 320px;
      height: 115px;
      padding: 15px 15px 20px 15px;
      background: #f6f7fb;
      border-radius: 8px;
      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #272d2d;
        opacity: 0.3;
        margin-bottom: 15px;
      }
      .cropPhoto {
        width: 50px;
        height: 50px;
        border-radius: 12px;
        margin-right: 15px;
      }
      .details {
        display: flex;
        flex-direction: column;
        .spec {
          color: #4b9bf5;
          margin-top: 4px;
        }
      }
    }

    .btn {
      max-width: 100%;
      margin-bottom: 20px;
      height: auto;
    }

    .formFooter {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      .btn {
        height: 50px;
        margin-bottom: 10px;
      }
      div {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        .danger {
          margin: 0;
          margin-right: 10px;
        }
        .secondary {
          margin: 0;
          justify-content: center;
          color: #4b9bf5;
          &:hover {
            border: 1px solid #4b9bf5;
          }
        }
      }
    }
  }
}
.commonInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0 30px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #272d2d;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 25px;
}

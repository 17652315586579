.onboardingDashBoardContainer {
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  .title {
    font-family: "SF UI Display Medium";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 30px;
  }
  .onboardingItemsContainer {
    margin-top: 44px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .onboardingItem {
      position: relative;
      background: #ffffff;
      width: 100%;
      max-width: 300px;
      min-width: 200px;
      height: 220px;
      border-radius: 8px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      margin-right: 20px;
      img {
        width: auto;
        height: auto;
        max-width: 280px;
        max-height: 200px;
      }
      // &:hover {
      //   box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      // }
      .edit {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 36px;
        height: 36px;
        border: 0;
        background: white;
        border-radius: 17.5px;
        outline: none;
        &:hover {
          box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
        }
      }
    }
    &.empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 110px);
      p {
        align-items: center;
        font-family: "SF UI Display Medium";
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        color: #b9b9b9;
      }
    }
  }
}

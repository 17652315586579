.spinner {
  position: relative;
  width: 100px;
  height: 100px;

  $size: 5px;

  .dbl-spinner {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: transparent;
    border: $size solid transparent;
    border-top: $size solid #f8c923;
    border-left: $size solid #f8c923;
    -webkit-animation: 2s spin linear infinite;
    animation: 2s spin linear infinite;
  }

  .dbl-spinner--2 {
    border: $size solid transparent;
    border-right: $size solid #4b9bf5;
    border-bottom: $size solid #4b9bf5;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
  }

  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

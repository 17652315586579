.inputFile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 320px;
  height: 193px;
  background: #f6f7fb;
  margin-top: 20px;
  border-radius: 16px;
  border: 1px dashed #4b9bf5;
  cursor: pointer;
  &.hidden {
    @extend .inputFile;
    visibility: hidden;
    display: none;
  }
  input[type="file"] {
    visibility: hidden;
    display: none;
  }

  .background {
    width: 100%;
    max-width: 320px;
    background-color: gray;
  }
}

.cropper {
  @extend .inputFile;
  margin-top: 30px;
  border: 0;
  height: 100%;
  max-height: 200px;
  background: unset;
  .cropper-container {
    background: unset;
    height: inherit;
    .cropper-crop,
    .cropper-wrap-box {
      border-radius: 16px;
    }
  }
}

.fileName {
  display: flex;
  justify-content: center;
  font-style: normal;
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #272d2d;
}

.uploadFooter {
  .btn {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

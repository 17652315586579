.formField {
  text-align: left;
  height: 80px;

  &.textArea {
    display: table;
    max-width: 317px;
    padding: 15px;
    width: 100%;
    height: auto;
    background: #f6f7fb;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 20px;
    margin-top: 5px;
    &.fill {
      @extend .textArea;
      padding-top: 17px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    textarea {
      color: #272d2d;
      //max-width: 290px;
      //max-height: 150px;
      //width: 100px;
      //height: 50px;
      min-height: 21px;
      //min-width: 287px;
      width: 100%;
      resize: vertical;
      background: #f6f7fb;
      border-radius: 8px;
      border: 0;
      outline: unset;
      &::placeholder {
        color: #b9b9b9;
      }
    }
  }
  .fieldInput {
    img.shevron,
    img.circle {
      position: absolute;
      top: 17px;
      right: 15px;
      z-index: 1;
    }
    .clearInputFieldBtn {
      position: absolute;
      right: 17px;
      top: 17px;
    }
  }
  &.input-phone {
    .inputLabel {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      position: absolute;
      font-size: 10px;
      top: 9px;
      left: 15px;
      color: #b9b9b9;
    }
    .react-tel-input {
      .form-control {
        width: 100%;
        max-width: 310px;
        height: 50px;
        border: none;
        background: #f6f7fb;
        border-radius: 8px;
      }
      .flag-dropdown {
        border: none;
        .selected-flag {
          background: #f6f7fb;
          &.open {
            background: #f6f7fb;
          }
        }
      }
    }
  }
  .formItemError {
    margin: 0;
    padding-top: 10px;
    padding-left: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #fd3845;
  }

  .scrollarea-content {
    z-index: 100;
  }
}

.invisible {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.wrapperOptions {
  z-index: 10;
  .options {
    padding: 10px;
    background: white;
    border-radius: 8px;
    width: 100%;
    max-width: 315px;
    border: 1px solid #f6f7fb;
    .option {
      cursor: pointer;
      padding: 15px 0;
      border-bottom: 1px solid #e7e7e7;
      &:last-child {
        border: none;
      }
    }
  }
}
textarea::-webkit-scrollbar {
  width: 0px;
  height: auto;
}

.option {
  cursor: pointer;
}

@import "./Input/input.scss";
@import "../ui/Button/button.scss";

.background {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  .wrappPasswordForm {
    .formField {
      margin-top: 20px;
      height: auto;
      .fieldInput {
        font-weight: 500;
      }
    }
    margin: auto;
    text-align: center;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      color: #272d2d;
      // display: flex;
      // text-align: left;
      // position: absolute;
      // top: -30%;
      // width: max-content;
      // left: -50%;

      width: 100%;
      margin: 0;
    }
    img {
      margin-top: 45px;
    }
    .passwordForm {
      max-width: 360px;
      width: 100%;
      margin: 0;
      .container {
        margin-top: 60px;
      }
      .formFooter {
        margin-top: 20px;
      }
      // .btn {
      //   &:disabled {
      //     background: #efefef;
      //     font-weight: 500;
      //     color: #b9b9b9;
      //   }
      // }
    }
  }
}

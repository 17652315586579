.employeeListContainer {
  width: 100%;
  max-width: 960px;
  padding-right: 20px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
  }
  scrollbar-width: none;
  .titleWithFilters {
    display: flex;
    margin-bottom: 30px;
    //margin-top: 25px;
    .title {
      font-family: "SF UI Display Medium";
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      margin-right: 40px;
    }
    .filters {
      display: flex;
      align-items: center;
      width: 100%;
      .filter {
        border: 1px solid #bec0c0;
        font-size: 12px;
        border-radius: 15px;
        color: #bec0c0;
        height: 30px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        margin-right: 10px;
        cursor: pointer;
        &.active {
          border: 1px solid #4b9bf5;
          background: #4b9bf5;
          color: white;
        }
      }
    }
  }
  .employeeItemsContainer {
    position: relative;
    width: 100%;
    overflow: auto;
    height: calc(100% - 120px);
    border-radius: 8px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0px;
      border-radius: 2px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 0px;
      height: 10%;
      border-radius: 2px;
      background: #4b9bf5;
    }

    & .employeeEmptyText {
      text-align: center;
      width: 100%;
      font-family: SF UI Display, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 33px;
      color: #b9b9b9;
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 110px);
    }

    .employeeItem {
      display: flex;
      width: 100%;
      padding: 15px 20px;
      height: 1px;
      min-height: 131px;
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 20px;
      transition: 0.15s ease-in-out;
      // &:hover {
      //   transition: 0.15s ease-in-out;
      //   box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      // }
      &.editMode {
        opacity: 0.6;
      }
      &.editItem {
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      }
      &.locked {
        position: relative;
        // opacity: 0.5;
      }
      .locker {
        width: 8px;
        height: 8px;
        background-color: #fce022;
        border-radius: 50%;
        position: absolute;
        bottom: 20px;
        left: 20px;
      }
      .title {
        font-family: "SF UI Display Medium";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #272d2d;
        opacity: 0.3;
        // margin-bottom: 15px;
      }
      .id,
      .specialization,
      .phone {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .specializationInfo {
          padding-right: 15px;
          img {
            width: 50px;
            height: 50px;
            border-radius: 12px;
            background-color: #e5e5e5;
          }
        }
      }
      .idInfo,
      .specializationInfo,
      .phoneInfo {
        height: 100%;
        display: flex;
        align-items: center;
        .name,
        .phoneNumber {
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #272d2d;
        }
        .phoneNumber {
          margin: 0;
        }
        img {
          margin-right: 15px;
        }
        .details {
          display: flex;
          flex-direction: column;
          .spec {
            color: #4b9bf5;
            margin-top: 4px;
          }
        }
      }
      .id {
        width: 10%;
      }
      .specialization {
        width: 45%;
      }
      .phone {
        width: 35%;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #272d2d;
      }
      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 10%;
        img {
          z-index: 150;
          margin-top: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

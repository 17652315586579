.formField {
  position: relative;
  height: auto;
  &.picker {
    min-height: 57px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .datePicker {
    color: #000;
    font-size: 16px;
    width: 100%;
    background: #f6f7fb;
    max-width: 90px;
    input {
      padding: 16px;
      border-radius: 8px;
      border: 0;
      width: 100%;
      font-family: "SF UI Display Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #272d2d;
      background: #f6f7fb;
      outline: none;
      &::placeholder {
        color: #b9b9b9;
        font-weight: 300;
      }

      &:disabled {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    &.error {
      color: initial;
      input {
        border: 2px solid red;
      }
    }

    .rdtPicker {
      width: 310px;
      padding: 20px;
      right: 4px;
      min-width: 250px;
      box-shadow: 0 0 4px rgba(15, 15, 16, 0.14);
      border-radius: 8px;
      .rdtDays {
        max-width: 350px;
      }
      .rdtSwitch,
      .rdtPrev,
      .rdtNext {
        font-size: 18px;
        color: #272d2d;
        font-weight: 600;
      }

      .rdtPrev,
      .rdtNext {
        padding: 0 10px;
      }

      table {
        border-collapse: collapse;
        thead {
          tr {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:nth-child(1) {
              margin-bottom: 20px;
            }
            .rdtSwitch {
              width: max-content;
            }
          }
        }
        tbody {
          margin: 0;
          padding: 0;
          border: 0;
        }
        tr {
          margin: 0;
          padding: 0;
          border: 0;
          display: flex;
          td.rdtDay {
            flex: 0 0 30px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            // border: 1px solid rgba(22, 34, 55, 0.1);
            &:hover {
              background: #ececec;
              border-radius: 50%;
            }
            @media screen and (max-width: 480px) {
              flex: 0 0 34px;
              width: 34px;
              height: 34px;
            }
          }
          td.rdtActive {
            background-color: #4b9bf5;
            border-radius: 50%;
          }
          .rdtToday {
            &:before {
              content: none;
            }
          }
          .rdtMonth,
          .rdtYear {
            text-align: center;
            padding: 11px 0;
            margin-top: 10px;
            margin-bottom: 10px;
            flex: 0 1 30%;
            max-width: 30%;
            height: 40px;
          }
        }
      }

      @media screen and (max-width: 480px) {
        width: 280px;
      }
    }
  }
  .hiddingEl {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 0;
    top: 0;
  }
  .children {
    height: 100%;
    padding: 0 15px 12px;
    background: #f6f7fb;

    .selectedDates {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      .date {
        display: flex;
        align-items: center;
        font-family: "SF UI Display Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        padding: 4px 10px;
        margin: 5px;
        // height: max-content;
        background: rgba(75, 155, 245, 0.31);
        border-radius: 12px;
        img {
          margin-left: 7px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

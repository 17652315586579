.optionSelectingContainer {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  max-width: 350px;
  padding: 20px 15px;
  height: 100%;
  background: white;
  border-radius: 8px;
  transition: 0.25s ease-in-out;
  display: none;
  .formTitle {
    margin-bottom: 25px;
    img {
      margin-right: 20px;
      cursor: pointer;
    }
  }
  &.show {
    display: block;
    left: 0;
    transition: 0.25s ease-in-out;
    z-index: 200;
  }
  .inputsContainer {
    width: 310px;
    display: flex;
    flex-direction: column;
    .addressInput {
      width: 100%;
      border-radius: 8px;
      border: none;
      outline: none;
      background-color: #f6f7fb;
      height: 50px;
      padding-left: 15px;
      margin-top: 20px;
    }
    .inlineInputs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .addressInput.inline {
        width: 145px;
      }
    }
    .errorAddress {
      font-family: SF UI Display, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #fd3845;
      display: none;
    }
    .errorAddress.shown {
      display: block;
    }
  }
}

.wrappNewShiftForm {
  position: absolute;
  top: 0;
  right: -400px;
  height: 100%;
  width: 100%;
  max-width: 350px;
  padding: 25px 15px;
  background: white;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.25);
  border-radius: 8px 0px 0px 8px;
  transition: 0.5s ease-in-out;
  z-index: 11;
  &.show {
    transition: 0.5s ease-in-out;
    right: 0;
  }
  .formTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    padding: 0 5px;
    margin-bottom: 30px;
    img {
      cursor: pointer;
    }
  }
  .optionSelectingContainer {
    padding: 40px 25px;
    &.show {
      .formTitle {
        justify-content: start;
        align-items: flex-start;
      }
    }
  }
  .newShiftForm {
    width: 100%;
    max-width: 350px;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .formContent {
      // position: relative;
      width: 100%;
      margin-top: 20px;
      height: 100%;
      overflow: scroll;

      min-width: 321px;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 0px;
        border-radius: 2px;
        background: unset;
      }
      &::-webkit-scrollbar-thumb {
        width: 0px;
        height: 10%;
        border-radius: 2px;
        background: #4b9bf5;
      }
      .datePicker {
        width: 100%;
        max-width: 100%;
        // padding: 16px;
        background: #f6f7fb;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .rdtPicker {
          width: 310px;
          right: 4px;
          .rdtDays {
            max-width: 350px;
          }
          tr {
            margin: 0;
            padding: 0;
            border: 0;
            display: flex;
            td.rdtDay {
              flex: 0 0 30px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;
              &:hover {
                background: #ececec;
                border-radius: 50%;
              }
              @media screen and (max-width: 480px) {
                flex: 0 0 34px;
                width: 34px;
                height: 34px;
              }
            }
          }
        }
      }
      .children {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .btn.secondary {
        height: auto;
        span {
          img {
            border-radius: 8px;
          }
        }
      }
      .period {
        position: relative;
        .close,
        .calendar {
          position: absolute;
          top: 15px;
          right: 40px;
          width: 18px;
          height: 18px;
          margin: 0 5px;
        }
        .close {
          cursor: pointer;
          z-index: 0;
        }
        .calendar {
          right: 15px;
        }
      }
      .period > .formField > .datePicker {
        border-radius: 8px;
      }
      .formItemError {
        padding: 0;
        margin: 5px 0 0 5px;
      }
    }
    .formFooter {
      .btn {
        margin-bottom: 20px;
        &.danger {
          margin: 0;
          height: 50px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .optionSelectingContainer.show {
      .option {
        padding-bottom: 15px;
        padding-top: 15px;
        box-sizing: content-box;
        span {
          line-height: 17px;
          img {
            height: 40px;
            width: 40px;
            border-radius: 8px;
          }
        }
        img {
          height: 22px;
          width: 22px;
        }
      }
    }
    .btn {
      width: 100%;
      max-width: 320px;
      margin-bottom: 20px;
    }
  }
}

.wrappNewSpecializationForm {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 350px;
  background: #ffffff;
  border-radius: 8px;
  padding: 15px;
  overflow: hidden;
  .formTitle {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    padding: 0 5px;
    &.edit {
      @extend .formTitle;
      justify-content: space-between;
    }
    img {
      cursor: pointer;
    }
  }
  .newSpecializationForm {
    width: 100%;
    max-width: 350px;
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .formContent {
      width: 101%;
      margin-top: 30px;
      height: 100%;
      overflow: scroll;

      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 0px;
        border-radius: 2px;
        background: unset;
      }
      &::-webkit-scrollbar-thumb {
        width: 0px;
        height: 10%;
        border-radius: 2px;
        background: #4b9bf5;
      }
      .specAvatar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 320px;
        background: #f6f7fb;
        border-radius: 8px;
        padding: 8px 15px;
        margin-bottom: 20px;
        img {
          width: 34px;
          height: 34px;
          border-radius: 8px;
        }
        .fileName {
          width: 100%;
          max-width: 210px;
          overflow: hidden;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          margin: 0 15px;
          color: #272d2d;
        }
        .trashIcon {
          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .secondary.btn {
        width: 100%;
        max-width: 320px;
      }
      .btn {
        width: 100%;
        max-width: 350px;
        margin-bottom: 20px;
      }
    }
    .uploadFooter {
      .btn {
        width: 100%;
        max-width: 350px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .formFooter {
      margin-bottom: 20px;
      .btn {
        width: 100%;
        height: 50px;
        max-width: 350px;
        &.danger {
          height: 50px;
          margin: 0;
          margin-top: 10px;
        }
      }
    }
  }
}

.newSymptomFormWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 350px;
  background: #ffffff;
  border-radius: 8px;
  padding: 15px;
  overflow: hidden;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .formTitle {
    font-weight: bold;
    text-align: left;
    font-size: 20px;
    line-height: 24px;
    margin-top: 25px;
    margin-bottom: 30px;
    padding: 0 5px;

    &.edit {
      @extend .formTitle;
      text-align: left;

      .titleBackArrow {
        float: right;
        &::before,
        &::after {
          content: "";
          display: none;
          clear: both;
        }
      }
    }

    img {
      cursor: pointer;
    }
  }

  .newSymptomForm {
    width: 100%;
    max-width: 350px;
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;

    .formContent {
      height: auto;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0px;
        border-radius: 2px;
        background: unset;
      }

      &::-webkit-scrollbar-thumb {
        width: 0px;
        height: 10%;
        border-radius: 2px;
        background: #4b9bf5;
      }

      .separator {
        width: 100%;
        height: 1px;
        border-top: 1px solid #b9b9b9;
        margin: 15px 0;
      }

      .symptomList {
        height: auto;
        .symptomItem {
          position: relative;
          opacity: 0.6;

          .formField > textarea {
            padding-right: 50px;
          }

          &.edited {
            opacity: 1;
          }

          &.faded {
            opacity: 0.6;
          }

          .symptomText {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #272d2d;
            margin: 0;

            &::before,
            &::after {
              content: "";
              display: none;
              clear: both;
            }
          }

          .symptomTrashIcon {
            position: absolute;
            top: 15px;
            right: 5px;
            width: 20px;
            height: 20px;
            cursor: pointer;
            float: right;
          }
          .editIcon {
            position: absolute;
            top: 15px;
            right: 35px;
            width: 20px;
            height: 20px;
            cursor: pointer;
            float: right;
          }
          .emptyText {
            text-align: center;
          }
        }
      }
    }

    .formFooter {
      .btn {
        margin-top: 20px;
        width: 100%;
        height: 50px;
        max-width: 350px;
      }
    }
  }
}

.newCardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  height: calc(100% - 75px);
  width: 350px;
  padding: 20px;
  margin-right: 20px;

  .newCardHeading {
    display: flex;
    justify-content: space-between;
    width: 310px;
    margin-bottom: 20px;

    .heading {
      font-family: SF UI Display, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #272d2d;
    }
    img {
      height: 24px;
      width: 24px;
    }
  }
  .newCardFormWrapper {
    overflow-x: hidden;
    scrollbar-width: none;
    // margin-bottom: 3em;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-radius: 10px;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #4b9bf5;
    }
    .wrapper_checkbox {
      margin-bottom: 20px;
    }
    .formField {
      &.picker {
        width: 100%;
        .datePicker {
          max-width: 100%;
        }
      }

      .wrapperOptions {
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        .options {
          .option {
            border: unset;
          }
        }
      }
    }

    .formFieldDoc.textAreaDoc {
      //background-color: #7DD952;
      margin-top: 20px;
      textarea::-webkit-input-placeholder {
        color: #b9b9b9;
      }
      textarea:-moz-placeholder {
        /* Firefox 18- */
        color: #b9b9b9;
      }
      textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: #b9b9b9;
      }
      textarea:-ms-input-placeholder {
        color: #b9b9b9;
      }
      textarea::placeholder {
        color: #b9b9b9;
      }
      textarea {
        padding-top: 15px;
      }
    }

    .wrap_checkbox .checkbox + label {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      margin-left: 25px;
    }
    .wrap_checkbox .checkbox + label::before {
      height: 22px;
      width: 22px;
      // top: 2px;
      zoom: 1.2;
    }
    .addressWrapper {
      width: 310px;
      height: auto;
      min-height: 60px;
      margin-bottom: 20px;
      button {
        min-height: 50px;
        height: auto;
        span {
          display: block;
          padding: 0 5px;
        }
      }
      .blur {
        span {
          color: #b9b9b9;
        }
      }
      .optionSelectingContainer.show {
        .formTitle {
          img {
            cursor: pointer;
          }
        }
      }
    }
    .btn {
      width: 310px;
    }
    .btn.submit {
      span {
        color: white;
      }
    }
    .optionSelectingContainerCustomized.shown {
      top: 35%;
      left: 15px;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    }
    .fieldWrapper {
      position: relative;
      .optionSelectingContainerCustomized.shown {
        // top: 30%;
        // left: 15px;
        left: 0;
        top: 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        .option {
          height: 40px;
        }
      }
    }
    .formTitle {
      font-family: SF UI Display, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #b9b9b9;

      margin-bottom: 10px;
    }
    button {
      // margin-top: 20px;
      // margin-bottom: 20px;
      span {
        display: block;
        // color: #b9b9b9;
        .selectedOption {
          color: black;
        }
      }
    }
  }
}

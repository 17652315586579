.doctorWrapper {
  width: 100%;
  min-height: 100%;
  .locker {
    width: 100%;
    height: 100%;
    background: #e5e5e5;
  }
  .header {
    display: flex;
    justify-content: space-between;
    //height: 40px;
    .clientInfo {
      .arrow-down {
        width: 20px;
        height: 10px;
        margin-left: 15px;
        margin-top: 7px;
        cursor: pointer;
      }
      .subscriber {
        margin-left: 10px;
        width: 25px;
        height: 25px;
        .blueCircle {
          position: relative;
          background-color: #4b9bf5;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          .checkMark {
            position: absolute;
            width: 15px;
            height: 8px;
            border-left: #e5e5e5 3px solid;
            border-bottom: #e5e5e5 3px solid;
            top: 40%;
            left: 51%;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
      .arrow-left {
        margin-top: 10px;
      }
      .patientImage {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin: 0 0 0 27px;
        border-radius: 8px;
        background-color: #f6f7fb;
        font-family: SF UI Display, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #4b9bf5;
      }
      .doctorImage {
        width: 40px;
        height: 40px;
        margin-left: 27px;
        border-radius: 8px;
      }
    }

    button {
      width: 200px;
      span {
        display: flex;
        align-items: center;
        width: 200px;
        font-family: SF UI Display, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #272d2d;
        img {
          width: 30px;
          height: 30px;
          // margin-top: -6px;
          margin-right: 15px;
        }
      }
    }
  }
}
.clientInfo {
  display: flex;
  width: 100%;
  .doctorDetails {
  }
}

.doctorName,
.doctorPhone,
.doctorMe {
  font-family: SF UI Display, sans-serif;
  font-style: normal;
  color: #272d2d;
  margin-left: 20px;
}
.doctorName {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
.doctorPhone {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.doctorMe {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #4b9bf5;
}

.doctorContentContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 20px;
  height: 100%;
}

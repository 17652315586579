.wrappNewOnboardingForm {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 350px;
  background: #ffffff;
  border-radius: 8px;
  padding: 15px;
  overflow: hidden;
  .formTitle {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    padding: 0 5px;
    &.edit {
      @extend .formTitle;
      justify-content: space-between;
    }
    img {
      cursor: pointer;
    }
  }
  .newOnboardingForm {
    width: 100%;
    max-width: 350px;
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .formContent {
      width: 100%;
      margin-top: 30px;
      height: 100%;

      .background {
        width: 100%;
        max-width: 320px;
        background-color: #e5e5e5;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 193px;
        // height: 100%;
        img {
          background: #f6f7fb;
          width: auto;
          height: auto;
          max-width: 280px;
          max-height: 193px;
        }
      }
      .croppImage {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
        max-width: 320px;
        margin-bottom: 30px;
        .trashIcon {
          position: absolute;
          width: 36px;
          height: 36px;
          top: 10px;
          right: 10px;
          border-radius: 50%;
          background: #ffffff;
          border-radius: 17.5px;
          display: flex;
          justify-content: center;
          cursor: pointer;
          align-items: center;
          &:hover {
            box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
        > img {
          background: #f6f7fb;
          width: auto;
          height: auto;
          max-width: 280px;
          max-height: 193px;
        }
        .fileName {
          display: table-cell;
          word-break: break-all;
          width: 320px;
          height: auto;
          margin-top: 15px;
          margin-right: auto;
        }
      }
    }
    .formFooter {
      &.edit {
        display: flex;
        flex-direction: column;
        .btn {
          margin: 0 0 20px 0;
          height: 50px;
        }
      }
    }
    .formField {
      width: 100%;
      max-height: 60px;
    }
    .btn {
      width: 100%;
      max-width: 350px;
      margin-bottom: 20px;
    }
  }
}
.addingImg {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 101%;
  // transition: 0.25s ease-in-out;
  width: 100%;
  max-width: 350px;
  padding: 15px;
  height: 100%;
  &.show {
    @extend .addingImg;
    @extend .wrappNewOnboardingForm;
    left: 0;
    transition: 0.25s ease-in-out;
    z-index: 10;
    .formTitle {
      @extend .formTitle;
      padding: 0;
      line-height: 23px;
      img {
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
}
.photoUploadFieldContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 40px);
}

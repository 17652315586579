.subscribersListWrapper {
  width: 100%;
  position: relative;
  max-width: 960px;
  overflow: auto;
  max-height: calc(100vh - 120px);

  .title {
    .backArrow {
      margin-right: 20px;
      cursor: pointer;
    }
    .pageTitle {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      margin-right: 40px;
    }
  }

  .subscribersListItemsContainer {
    display: grid;
    grid-template-columns: repeat(4, calc((100% / 4) - 10px));
    grid-gap: 10px;
    height: calc(100% - 132px);
    overflow: scroll;
    align-content: baseline;
    margin-top: 44px;

    .subscribersListItem {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      background-color: #fff;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 17px 20px;
      height: 100%;

      .subscriberAvatar {
        width: 50px;
        height: 50px;
        border-radius: 8px;
      }

      .subscriberTitle {
        font-family: SF UI Display, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #b9b9b9;
      }

      .subscriberFullName {
        font-family: SF UI Display, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #272d2d;
      }

      .subscriberPoliceNumber,
      .insuranceDateStart,
      .insuranceDateEnd {
        margin: 5px 0;
        font-family: SF UI Display, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        .text-grey {
          color: #b9b9b9;
          font-family: SF UI Display, sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
        }
      }

      .subscriberFooter {
        margin-top: auto;
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: space-between;

        .idBlock {
          font-family: SF UI Display, sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;

          .itemIdTitle {
            color: #b9b9b9;
            margin-right: 10px;
          }

          .itemIdValue {
            color: #272d2d;
          }
        }

        .editIcon {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
    }
  }

  .paginator {
  }
}

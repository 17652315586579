.clientWrapper {
  width: 100%;
  min-height: 100%;
  .newClientContentContainer {
    .doctorChat,
    .newCardContainer,
    .newConsultationWrapper {
      height: calc(100% - 24px);
      border-radius: 8px;
      position: relative;
    }
  }
  .btn.alignCenter.flex.noBackground {
    width: 200px;
    span {
      display: flex;
      justify-content: space-between;
      width: 200px;
      padding-top: 6px;
      font-family: SF UI Display, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #272d2d;
      img {
        width: 30px;
        height: 30px;
        margin-top: -6px;
      }
    }
  }
}
.clientInfo {
  display: flex;
}
.doctorImage {
  width: 40px;
  height: 40px;
  margin-left: 27.25px;
  border-radius: 8px;
}
.doctorName,
.doctorPhone,
.doctorMe {
  font-family: SF UI Display, sans-serif;
  font-style: normal;
  color: #272d2d;
  margin-left: 20px;
}
.doctorName {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}
.doctorPhone {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.doctorMe {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #4b9bf5;
}
.arrow-down {
  width: 14.83px;
  height: 8.01px;
  margin-left: 15px;
  margin-top: 7px;
}
.newClientContentContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  // padding-bottom: 20px;
  height: 100%;
}

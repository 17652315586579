@import "./Sider/sider.scss";

.paginator {
  display: flex;
  //align-items: center;
  align-items: flex-end;
  justify-content: left;
  width: 100%;
  //height: 60px;
  height: 50px;
  .paginationItem {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    // margin-right: 15px;
    color: #272d2d;
    cursor: pointer;
    &.active {
      @extend .paginationItem;
      color: #272d2d;
      background: #fce022;
    }
  }

  .paginatorDots {
    height: 24px;
  }

  .paginationItem.active {
    @extend .paginationItem;
    color: #272d2d;
    background: #fce022;
  }
}

.history-item-page {
  color: #272d2d;
  height: 100%;
  .absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .to-back {
    cursor: pointer;
    display: inline-block;
    margin-top: 3px;
  }
  .history-item {
    //height: 50px;
  }

  .header {
    display: block;
    position: relative;
    //margin-bottom: 25px;

    .to-back {
      position: absolute;
    }

    .main-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: auto;
      text-align: center;

      .doctorImage {
        margin: 10px 0;
        width: 40px;
        height: 40px;
        margin: 0;
      }

      .doctor-full-name {
        margin: 10px 0;
        font-size: 16px;
        //font-style: normal;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
      }

      .doctor {
        margin: 10px 0;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
      }

      .date {
        margin: 10px 0;
        font-size: 14px;
        line-height: 17px;
        color: #b9b9b9;
      }

      img {
        width: 34px;
        height: 34px;
        object-fit: cover;
      }
    }
  }

  .item-content {
    overflow-y: auto;
    height: 72%;
    margin: 0 -20px;
    // padding: 0 20px;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-radius: 10px;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #4b9bf5;
    }

    strong {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }

    .separator {
      height: 1px;
      background: #e7e7e7;
      border-radius: 1px;
      margin: 0 20px;
    }

    .text-field {
      margin: 20px 0;
      padding: 0 20px;
      width: 100%;
      height: auto;

      .title {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        //margin-bottom: 15px;
        text-align: center;
      }

      .text {
        min-height: 40px;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        flex-direction: column;
      }

      .text-separator {
        width: 256px;
        height: 1px;
        margin: 0;
      }

      &.gray {
        background: #f6f7fb;
        padding: 5px 20px;
        // border-radius: 8px;
      }

      .info {
        display: flex;
        align-items: center;

        img {
          width: 34px;
          height: 34px;
          object-fit: cover;
          display: inline-block;
          margin-right: 15px;
        }
      }
      .sendToInsurance {
        .send {
          margin: 10px 0;
        }
      }
    }
  }

  &.details {
    .header {
      display: flex;
      justify-content: start;
      height: 24px;
      line-height: 24px;
      margin-bottom: 20px;

      .to-back {
        position: static;
        margin: 0;
      }

      .text {
        text-align: right;
        font-weight: bold;
        font-size: 20px;
        margin-left: 32px;
      }
    }

    .doctor {
      display: flex;
      font-size: 14px;
      line-height: 17px;
      .docAvatar {
        img {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          margin-right: 15px;
        }
      }
      .docinfo {
        width: 100%;
        .type {
          margin-top: 4px;
          color: #4b9bf5;
        }
      }
    }

    .separator {
      height: 1px;
      background: #e7e7e7;
      border-radius: 1px;
      margin: 16px 0;
    }

    .string {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .date-cost {
      b {
        font-size: 16px;
        line-height: 19px;
      }
    }

    .id-status {
      .id {
        font-size: 14px;
        line-height: 17px;
        color: #b9b9b9;
      }
      .status {
        background: #52d9d1;
        // background: #7DD952;
        border-radius: 15px;
        color: white;
        font-size: 12px;
        line-height: 14px;
        padding: 6px 23px;
      }
    }

    .pad-left {
      display: flex;
      align-items: center;
      // padding-left: 40px;

      img {
        width: 25px;
        height: 25px;
        border-radius: 4px;
        margin-right: 15px;
        background: #e7e7e7;
      }

      span,
      b {
        font-size: 14px;
        line-height: 17px;
      }

      margin: 20px 0;
    }
    .icon-text {
      display: flex;
      align-items: center;
      margin: 15px 0;

      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
        display: inline-block;
        margin-right: 16px;
      }
    }

    .gray-text {
      color: #b9b9b9;
    }
  }
}

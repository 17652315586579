.bell {
  width: 33px;
  height: 33px;
  display: flex;
  position: relative;
  cursor: pointer;
  .notification {
  }
  .unsearchNotifications {
    position: absolute;
    top: -5px;
    right: -2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f8c923;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: inline-block;
      padding-top: 1px;
      font-size: 12px;
      line-height: 13px;
    }
  }
}

.wrappSearchForm {
  height: 40px;
  width: 100%;
  max-width: 920px;
  .searchForm {
    .formField {
      .fieldInput {
        position: relative;
        img {
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translate(0, -50%);
          width: 18px;
          height: 18px;
          cursor: pointer;
          outline: unset;
        }
        input {
          padding-left: 45px;
          height: 40px;
        }
      }
    }
  }
}

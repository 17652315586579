.specializationListContainer {
  width: 100%;
  max-width: 960px;
  padding-right: 20px;
  // overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  scrollbar-width: none;
  .titleWithFilters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    //margin-top: 25px;
    .title {
      font-family: "SF UI Display Medium";
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
    }
    .select {
      max-width: 210px;
      .selectField {
        border: 0;
        outline: unset;
        width: 100%;
        background: unset;
      }
    }
  }
  .specializationItemsContainer {
    position: relative;
    width: 100%;
    overflow: auto;
    height: calc(100% - 120px);
    border-radius: 8px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0px;
      border-radius: 2px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 0px;
      height: 10%;
      border-radius: 2px;
      background: #4b9bf5;
    }
    a {
      text-decoration: none;
      max-width: 230px;
      overflow: hidden;
      display: flex;
    }
    .specializationItem {
      display: flex;
      width: 100%;
      padding: 15px 20px;
      height: 1px;
      min-height: 131px;
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 20px;
      transition: 0.15s ease-in-out;
      &.editMode {
        opacity: 0.6;
      }
      &.editItem {
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      }
      .title {
        font-family: "SF UI Display Medium";
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #272d2d;
        opacity: 0.3;
      }

      .id,
      .specialization,
      .cost {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .specializationInfo {
          img {
            width: 50px;
            height: 50px;
            border-radius: 12px;
          }
        }
      }
      .idInfo,
      .specializationInfo,
      .costInfo {
        height: 100%;
        display: flex;
        align-items: center;
        .onLine {
          margin-right: 60px;
        }
        .name {
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #272d2d;
          margin-left: 15px;
        }
        .onLine,
        .home {
          display: flex;
          flex-direction: column;
          .type {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            color: #272d2d;
            margin-bottom: 5px;
          }
          .value {
            @extend .type;
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
      .id {
        width: 10%;
      }
      .specialization {
        width: 35%;
      }
      .cost {
        width: 45%;
      }
      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 10%;
        img {
          cursor: pointer;
          margin-top: 10px;
        }
      }
    }
    &.empty {
      width: 100%;
      height: calc(100% - 100px);
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        color: #b9b9b9;
      }
    }
  }
}

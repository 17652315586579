.previewBlock {
  padding: 14px;
  border-radius: 8px;
  margin: 20px 0;
  background: #f6f7fb;

  .previewImage {
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }
}

.subscribedUserWrapper {
  width: 100%;
  height: 100%;

  .title {
    margin-bottom: 30px;
    .pageTitle {
      font-family: "SF UI Display Medium";
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      margin: 0;
    }

    .btn {
      max-width: 310px;
      height: 38px;
    }
  }

  .subscribersListItemsContainer {
    height: calc(100% - 130px);
    overflow: scroll;
    scrollbar-width: none;
    border-radius: 12px;

    .subscribedUserItem {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100%;
      padding: 15px 20px;
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 20px;
      height: auto;

      .avatarBlock {
        width: 10%;
        text-align: center;

        .title {
          font-family: "SF UI Display Medium";
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #272d2d;
          opacity: 0.3;
          margin-right: 10px;
          overflow-wrap: anywhere;
        }
      }

      .leftBlock {
        width: 45%;

        .title {
          font-family: "SF UI Display Medium";
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #272d2d;
          opacity: 0.3;
          margin-right: 10px;
          overflow-wrap: anywhere;
        }
      }

      .rightBlock {
        width: 45%;

        .title {
          font-family: "SF UI Display Medium";
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #272d2d;
          opacity: 0.3;
          margin-right: 10px;
        }
      }
    }

    .errorMsg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 33px;
      color: #b9b9b9;
    }
  }
}

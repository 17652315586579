.panel-left {
  position: relative;
  max-width: 960px;
  width: 100%;
  padding-right: 20px;
  overflow: auto;
}

.panel-right {
  position: relative;
  max-width: 350px;
  width: 100%;
  padding: 25px 20px 20px 20px;
  background: #ffffff;
  border-radius: 8px;
}

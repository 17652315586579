.newConsultationWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  height: calc(100% - 65px);
  width: 350px;
  padding: 20px;

  &.withFullScreenVideo {
    z-index: 155;
  }
  .setting {
    font-family: SF UI Display, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #272d2d;
  }

  .formWrapper {
    height: 100%;
    .secondary.btn span {
      text-align: left;
    }
    form {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      overflow-x: hidden;
      align-items: center;
      scrollbar-width: none;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: transparent;
        border-radius: 10px;
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #4b9bf5;
      }
      .fieldsWrapper {
        height: 100%;
        display: flex;
        width: 100%;
        max-width: 310px;
        flex-direction: column;
        align-items: center;
        .btn {
          margin-top: 20px;
          span {
            img {
              max-width: 34px;
              max-height: 34px;
              border-radius: 8px;
              width: fit-content;
            }
          }
          &.disabled {
            span {
              img {
                width: 22px;
              }
            }
          }
          &.secondary {
            span {
              // width: 100%;
            }
          }
        }
      }
    }
    .optionSelectingContainer.show {
      position: absolute;
      .formTitle {
        font-family: SF UI Display, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #272d2d;
      }
      .periods {
        display: flex;
        justify-content: space-between;
        padding: 15px;
      }
      .filterOptions {
        .option {
          max-height: 70px;
        }
      }
    }

    .buttonWrapper {
      width: 310px;
      .price {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0 5px;
        .cost {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          color: #272d2d;
        }
      }
      .btn {
        margin-top: 5px;
        &.btn-submit {
          background: #7dd952;
        }
        &.btn-change {
          margin-top: 30px;
        }
        &.btn-cancel {
          margin-top: 5px !important;
        }
        &.danger {
          height: 50px;
          margin-top: 10px;
          max-width: 100%;
        }
      }
    }
  }
  .doneContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .contentWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 60%;
      .created,
      .doneContent {
        font-family: SF UI Display, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #272d2d;
        text-align: center;
        margin-top: 22px;
      }
      .doneContent {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin-top: 20px;
      }
    }
  }
}

.toggleFieldImg {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .optionItem {
    width: 155px;
    height: 132px;
    background: #f6f7fb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: #b9b9b9;
    cursor: pointer;
    &.active {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      color: #272d2d;
    }
    img {
      margin-bottom: 10px;
    }
    input {
      display: none;
    }
  }
}

.flex {
  display: flex;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyStart {
  justify-content: start;
}

.justifyAround {
  justify-content: space-around;
}

.justifyBeetwen {
  justify-content: space-between;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: end;
}

.alignStart {
  align-items: start;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

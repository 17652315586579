.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-height: 60px;
  border-radius: 8px;
  background-color: white;
  padding: 10px;
  margin-bottom: 20px;
  .searchContainer {
    width: 100%;
  }
  &.operator {
    .wrappSearchForm {
      max-width: 750px;
    }
  }
}

.notifications-modal {
  position: absolute;
  z-index: 11;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  width: 350px;
  height: calc(100vh - 120px);
  left: 0;
  top: 75px;
  display: flex;
  flex-direction: column;

  .btn {
    margin-top: auto;
  }

  .notification-item {
    position: relative;
    display: flex;
    cursor: pointer;
    margin-right: 10px;

    .type {
      img {
        position: absolute;
        bottom: 5%;
        right: 5%;
        margin-right: 15px;
      }
    }

    .notification-item-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-basis: 100%;
      width: 100%;
      min-height: 60px;
      word-break: break-word;

      .text {
        .title,
        .date,
        .id {
          margin: 1px 0 10px 0;
        }
        .title {
          font-size: 14px;
          line-height: 17px;
          padding-right: 5px;
        }
        .date,
        .id {
          font-size: 12px;
          line-height: 14px;
          color: #b9b9b9;
        }
      }

      img {
        opacity: 0.4;
      }
    }

    padding-bottom: 5px;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 15px;
    &:last-child {
      border-bottom-color: transparent;
      margin-bottom: 0;
    }
  }
}

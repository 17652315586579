.subscription-wrapper {
  display: grid;
  grid-template-columns: repeat(3, calc((100% / 3) - 20px));
  grid-gap: 20px;
  margin-top: 42px;
  margin-bottom: 20px;

  .subscription-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 17px 20px;
    height: 100%;
    cursor: pointer;

    .subscription {
      &-header {
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        color: #272d2d;
        opacity: 0.3;
      }

      &-title {
        font-size: 22px;
        font-weight: bold;
        line-height: 26px;
        color: #272d2d;
        max-height: 54px;
      }

      &-amount {
        font-size: 42px;
        line-height: 50px;
        font-weight: bold;
        color: #272d2d;
        margin-top: 24px;
        margin-bottom: 0;

        .subscription-patient {
          opacity: 0.3;
        }
      }
    }
  }

  .empty-list {
    color: #b9b9b9;
    font-size: 28px;
    font-weight: bold;
  }
}

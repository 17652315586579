.toggleField {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  max-width: 320px;
  border-radius: 8px;
  background: #f6f7fb;
  .optionItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 140px;
    height: 40px;
    border-radius: 8px;
    color: #b9b9b9;
    cursor: pointer;

    &.active {
      background: #ffffff;
      color: #272d2d;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    }
    input {
      display: none;
    }
  }
}

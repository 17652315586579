.dropdown {
  position: relative;
  outline: none;
  width: 100%;
  .wrapperChildren {
    position: absolute;
    z-index: 2;
    width: inherit;
    outline: none;
    right: 65px;
  }
}

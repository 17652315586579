.userToolContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  width: 100%;
  max-width: 340px;
  justify-content: space-between;
  padding-left: 10px;
  .avatar {
    margin-right: 15px;
    height: 40px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 8px;
    }
  }
  .name {
    margin-right: 25px;
    width: 100%;
  }
  .options {
    margin-right: 35px;
  }
  .dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .wrapperChildren {
      position: absolute;
      z-index: 15;
      .dropWindow {
        width: 273px;
        top: 40px;
        left: 100px;
        position: absolute;
        background: #ffffff;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        // .dropContent {
        //   display: flex;
        //   width: 100%;
        //   &::after {
        //     content: "";
        //     position: relative;
        //     display: flex;
        //     width: 100%;
        //     max-width: 233px;
        //     height: 1px;
        //     border-bottom: 1px solid #e7e7e7;
        //     margin-top: 15px;
        //   }
        //   .choiceOptions {
        //     display: flex;
        //     flex-direction: column;
        //     .choiceOption {
        //       cursor: pointer;
        //       display: flex;
        //       flex-direction: row;
        //       padding: 10px 0;
        //       span {
        //         display: block;
        //         margin-left: 15px;
        //       }
        //       &:first-child {
        //         padding-top: 0px;
        //       }
        //       .circleBlu {
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         width: 20px;
        //         height: 20px;
        //         border-radius: 50%;
        //         background-color: #4b9bf5;
        //         .circleWhite {
        //           width: 18px;
        //           height: 18px;
        //           border-radius: 50%;
        //           background-color: white;
        //           &.active {
        //             width: 10px;
        //             height: 10px;
        //           }
        //         }
        //       }
        //     }
        //   }
        //   .status {
        //     font-family: SF UI Display, sans-serif;
        //     font-style: normal;
        //     font-weight: 500;
        //     font-size: 14px;
        //     line-height: 17px;
        //     color: #b9b9b9;
        //     margin-top: 6px;
        //     margin-bottom: 20px;
        //     &.channels {
        //       margin-top: 20px;
        //     }
        //   }
        //   .inputarea {
        //     width: 233px;
        //     height: 50px;
        //     background: #f6f7fb;
        //     border-radius: 8px;
        //     margin-top: 15px;
        //     img {
        //       margin-left: 205px;
        //       margin-top: 22px;
        //     }
        //   }
        // }
        .btn {
          color: #fd3845;
          cursor: pointer;
          width: 140px;
          height: 40px;
          line-height: 40px;
          background-color: white;
          &:hover {
            background-color: #fd3845;
            color: white;
          }
        }
      }
    }
  }

  &.openedNotifications {
    position: relative;
    padding-left: 20px;
    padding-right: 10px;

    .not {
      font-size: 20px;
      line-height: 24px;
      color: #272d2d;
      font-weight: bold;
    }
    img {
      cursor: pointer;
    }
  }
}

.wrappFilterForm {
  position: relative;
  width: 100%;
  max-width: 350px;
  padding: 20px 15px;
  background: #ffffff;
  border-radius: 8px;
  .formTitle {
    display: flex;
    align-items: center;
    padding: 5px 0 0 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #272d2d;
    margin-bottom: 25px;

    .resetForm {
      margin-left: auto;
    }

    img {
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .monitoringFilters {
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .formContent {
      width: 100%;
      height: 100%;
      .btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 20px;
        padding-left: 15px;
        margin-top: 20px;
        width: 100%;
        max-width: 320px;
      }
      .btn.secondary {
        height: auto;
      }
    }
    .formFooter {
      .btn {
        width: 100%;
        max-width: 320px;
      }
    }
    .optionSelectingContainer.show {
      padding: 25px 15px;
      .option {
        padding-bottom: 15px;
        padding-top: 15px;
        box-sizing: content-box;
        span {
          img {
            height: 40px;
            width: 40px;
          }
        }
        img {
          height: 22px;
          width: 22px;
        }
      }
    }
  }
}

.dateRange {
  display: flex;
  align-items: center;
  padding: 11px;
  width: 100%;
  max-width: 320px;
  background: #f6f7fb;
  justify-content: space-between;
  border-radius: 8px;
  margin-top: 20px;
  height: 50px;

  .formField {
    display: flex;
    align-items: center;
    &.picker {
      margin: 0;
    }
    .datePicker {
      max-width: unset;
    }
  }
  img {
    width: 18px;
    height: 18px;
    margin: 0 5px;
  }
}
.btn {
  height: 50px;
  &.secondary {
    height: 50px;
  }
}

.modal-window {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 44;

  display: flex;
  align-items: center;
  justify-content: center;

  .modal-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 30;
  }

  .modal-content {
    position: relative;
    z-index: 156;
    //background: #FFFFFF;
    border-radius: 8px;
  }

  // #E7E7E7
  // #F6F7FB
}

.tagsInputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 50px;
  max-width: 250px;
  background: #ffffff;
  border-radius: 20px;
  height: 36px;
  cursor: pointer;
  .tagsInputField {
    overflow: scroll;
    align-items: center;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: inherit;
    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
    scrollbar-width: none;
    .activeTag {
      display: block;
      padding: 5px;
      z-index: 2;
      white-space: nowrap;
    }
    .iconToggle {
      position: absolute;
      left: 80%;
      z-index: 2;
      display: flex;
      width: 45px;
      height: 36px;
      justify-content: center;
    }
  }
  .tagList {
    position: absolute;
    z-index: -1;
    right: 5px;
    max-width: inherit;
    width: 100%;
    max-width: 210px;
    padding: 5px 15px 0 15px;
    scrollbar-width: none;
    &.active {
      @extend .tagList;
      z-index: 200;
      top: 150%;
      background: white;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      height: 100%;
      height: fit-content;
      max-height: 300px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px;
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        width: 0px;
        height: 10%;
        border-radius: 2px;
        background: #4b9bf5;
      }
      .tag {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        border-bottom: 1px solid #e7e7e7;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #272d2d;
        cursor: pointer;
        &:last-child {
          border: none;
        }
        img {
          width: 20px;
        }
      }
    }
  }
  &.disabled {
    background: #efefef;
    .tagsInputField {
      color: #b9b9b9;
    }
  }
}

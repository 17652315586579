@import "./App.scss";
@import "./style/fonts/style.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  font-family: "SF UI Display Medium"; // or Thin
  font-weight: normal;
  font-size: 14px;
}

#root {
  height: 100vh;
  background: #e5e5e5;
  padding: 20px;
}

.mainRowBox {
  height: 100%;
  display: flex;
}

main {
  display: flex;
  background: #e5e5e5;
  width: 100%;
  height: 100%;
}

.content {
  margin-left: 20px;
  width: 100%;
  max-width: 1310px;
  background-color: #e5e5e5;
  position: relative;
  overflow: hidden;
}

.contentContainer {
  display: flex;
  height: 100%;
  max-height: calc(100vh - 120px);
}

.bannersContainer,
.monitoringContainer,
.specializationContainer,
.employeeContainer,
.sheduleConauner,
.onboardingContainer {
  width: 100%;
}

.noBackground {
  background: none;
}

input,
button,
textarea {
  outline: none;
}

button {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.with-scrollbar {
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: transparent;
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #4b9bf5;
  }
}

.background {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 8px;
  .wrappLoginForm {
    .formField {
      margin-top: 20px;
      .fieldInput {
        font-weight: 500;
      }
    }
    margin: auto;
    text-align: center;
    max-width: 1400px;
    img {
      margin-top: 45px;
    }
    .loginForm {
      max-width: 360px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      transform: translate(-50%, -50%);
      h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 38px;
        color: #272d2d;
      }
      .container {
        margin-top: 60px;
      }
    }
  }
}

.promoCodesItemsContainer {
  width: 100%;
  height: calc(100% - 120px);
  position: relative;
  max-width: 960px;
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-content: baseline;
  border-radius: 8px;

  .promocodesItem {
    text-align: center;
    width: 23.9%;
    height: 150px;
    cursor: pointer;
    padding: 10px 15px;
    margin: 5px;
    background: #ffffff;
    border-radius: 8px;
    font-family: "SF UI Display Medium";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #272d2d;
  }
}
